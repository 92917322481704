import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../Asset/Css/Common.css";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import TopMenu from "../PageSetup/Tools/TopMenu";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { useTranslation } from "react-i18next";
import { InputSwitch } from "primereact/inputswitch";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import { ProgressSpinner } from "primereact/progressspinner";

const WithoutUser = () => {
  const history = useNavigate();
  const toast = useRef(null);
  const [visible, setVisible] = useState(true);
  const [screenvisible, setScreenVisible] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitAlert, setSubmitAlert] = useState(false);
  const [Years, setYears] = useState("");
  const initialFormData = {
    Username: "",
    Departmentname: "",
    Yearsofexp: Years,
    Improperactivity: "",
    Whocommited: "",
    Whenyourealized: "",
    Evidance: "",
    Others: "",
    Createdby: "admin",
    Createdon: new Date().toISOString(),
    Modifiedby: "admin",
    Modifiedon: new Date().toISOString(),
  };
  const [formData, setFormData] = useState({
    Username: "",
    Departmentname: "",
    Yearsofexp: Years,
    Improperactivity: "",
    Whocommited: "",
    Whenyourealized: "",
    Evidance: "",
    Others: "",
    Createdby: "admin",
    Createdon: new Date().toISOString(),
    Modifiedby: "admin",
    Modifiedon: new Date().toISOString(),
  });
  // useEffect(() => {
  //   setFormData(initialFormData);
  //   const storedFormData = JSON.parse(sessionStorage.getItem("form_one_data"));
  //   if (storedFormData) {
  //     setFormData(storedFormData);
  //     setYears(storedFormData.yearsofexp);
  //   }
  // }, []);

  const footerContent = (
    <div>
      <div className="flex flex-wrap justify-content-center flex-grow">
        <div className="flex flex-wrap justify-content-center flex-grow">
          <Button
            label="Done"
            icon="pi pi-check"
            onClick={() => setVisible(false)}
            style={{
              backgroundColor: "#025add",
              WebkitTextFillColor: "white",
            }}
            className="Done-button"
            autoFocus
            outlined
            rounded
          />
        </div>
      </div>
    </div>
  );
  const staticOptions = [
    { label: "Less than 1 year", value: "Less than 1 year" },
    { label: "1 ~ 3 years", value: "1 ~ 3 years" },
    { label: "3 ~ 5 years", value: "3 ~ 5 years" },
    { label: "5 ~ 10 years", value: "5 ~ 10 years" },
    { label: "Over 10 years", value: "Over 10 years" },
  ];

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const [checked, setChecked] = useState(false);
  const [lang, Langset] = useTranslation("global");
  const handleLangChange = (lang) => {
    Langset.changeLanguage(lang);
    sessionStorage.setItem("lang", lang);
  };

  const handleSwitchChange = () => {
    setChecked((prevChecked) => !prevChecked); // Toggle the switch state
    handleLangChange(checked ? "en" : "tn");
  };
  const isFormDataValid = () => {
    const requiredFields = [
      // "Username",
      // "Departmentname",
      // "Yearsofexp",
      "Improperactivity",
      // "Whocommited",
      // "Whenyourealized",
      // "Evidance",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };
  const confirm1 = () => {
    confirmDialog({
      group: "headless",
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept,
      reject,
    });
  };
  const handleNextButton = (e) => {
    console.log(formData);
    if (isFormDataValid()) {
      confirmDialog({
        message: lang("form.DetailConfirmation"),
        header: "📝  Desire to write more...",
        icon: "pi pi-right-arrow",
        defaultFocus: "reject",
        acceptClassName: "p-button-info",
        accept: () => accept(),
        reject: () => reject(),
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "CMS Error",
        detail: "Please Enter (1) Improper Activity",
      });
    }
  };
  const accept = () => {
    sessionStorage.setItem("form_one_data", JSON.stringify(formData));
    setScreenVisible(true);
  };

  const reject = () => {
    setShowSpinner(true);
    axios
      .post("/insertoneform", formData)
      .then((response) => {
        setShowSpinner(false);
        setSubmitAlert(true);
      })
      .catch((error) => {
        console.error("Error:", error); // Log error
        alert("An error occurred while saving data. abuse Session"); // Handle error case
      });
  };
  return (
    <div className="container Header-main">
      <TopMenu />
      <ConfirmDialog />
      <Toast ref={toast} position="bottom-right" />
      <div className="">
        <div className="box-form">
          <div className="black-background  font">
            <h3
              style={{ WebkitTextFillColor: "black", textAlign: "center" }}
              tabIndex={0}
            >
              {lang("form.header")}
            </h3>
            <hr />
            <div className=" ">
              <div className="grid">
                <div className="field col-3">
                  <div className="card  justify-content-center text-right">
                    <label htmlFor="status" className="label-field">
                      English
                    </label>
                    <InputSwitch
                      checked={checked}
                      onChange={handleSwitchChange}
                    />
                    <label htmlFor="status" className="label-field">
                      தமிழ்
                    </label>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        className="form-textbox"
                        onChange={handleChange}
                        name="Username"
                        value={formData.username}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.username")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        className="form-textbox"
                        onChange={handleChange}
                        name="Departmentname"
                        value={formData.departmentname}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.DepartmentName")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <Dropdown
                        options={staticOptions}
                        optionLabel="label"
                        className="form-MultiSelect"
                        value={Years}
                        onChange={(e) => {
                          setYears(e.target.value);
                          handleChange(e);
                        }}
                        name="Yearsofexp"
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.HowLong")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <p style={{ lineHeight: 1.5, textAlign: "center" }}>
                      {lang("form.summarize")}
                    </p>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        onChange={handleChange}
                        name="Improperactivity"
                        value={formData.improperactivity}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.Improper")}{" "}
                        <span
                          style={{
                            WebkitTextFillColor: "red",
                            fontSize: "17px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        name="Whocommited"
                        onChange={handleChange}
                        value={formData.whocommited}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.whoCommited")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        onChange={handleChange}
                        name="Whenyourealized"
                        value={formData.whenyourealized}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.realize")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        name="Evidance"
                        onChange={handleChange}
                        value={formData.evidance}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.evidance")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card  justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        onChange={handleChange}
                        name="Others"
                        value={formData.others}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("form.other")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-content-center flex-grow">
              <div className="flex flex-wrap justify-content-center flex-grow">
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-save"
                    rounded
                    outlined
                    aria-label="Submit"
                    label={lang("button.nextButton")}
                    onClick={handleNextButton}
                    className="Next-button"
                  />
                </div>
              </div>
            </div>
            <div className="card flex justify-content-center">
              <Dialog
                header="⚠️ Disclaimer"
                headerClassName="category-header-style"
                visible={visible}
                draggable={false}
                style={{ width: "auto", border: "1px solid black" }}
                onHide={() => setVisible(false)}
                footer={footerContent}
              >
                <div className="terms-and-conditions">
                  <p style={{ alignItems: "justify" }}>
                    🎯 Not eligible to enter the same complaints more than 2
                    times
                    <br></br>
                    <br></br>
                    🎯 Complaints Should be considered Inside or outside
                    Premises related to company
                    <br></br>
                    <br></br>
                    🎯 Rising Complaints only eligible based on their evidence
                    (Video or Documents)
                  </p>
                </div>
              </Dialog>
              <Dialog
                header={lang("listOfComplaints.header")}
                headerClassName="category-header-style"
                visible={screenvisible}
                draggable={false}
                style={{ width: "auto" }}
                onHide={() => setScreenVisible(false)}
              >
                <div className="field col-offset-1 text-center">
                  <br />
                  <Button
                    label={lang("listOfComplaints.unethical")}
                    onClick={() => {
                      history("/unethical");
                    }}
                    className="button-30"
                    outlined
                  ></Button>
                </div>
                <div className="field col-offset-1 text-center">
                  <Button
                    label={lang("listOfComplaints.bribery")}
                    onClick={() => {
                      history("/bribery");
                    }}
                    className="button-30"
                    outlined
                  />
                </div>
                <div className="field col-offset-1 text-center">
                  <Button
                    label={lang("listOfComplaints.abuse")}
                    onClick={() => {
                      history("/abuse");
                    }}
                    className="button-30"
                    outlined
                  />
                </div>
                <div className="field col-offset-1 text-center">
                  <Button
                    label={lang("listOfComplaints.employeeHarrasment")}
                    onClick={() => {
                      history("/harrashment");
                    }}
                    className="button-30"
                    outlined
                  />
                </div>
                {/* <div className="field col-offset-1 text-center">
                  <Button
                    label={lang("listOfComplaints.others")}
                    onClick={() => {
                      alert("Sorry !! 😢 This Page Note Available!!");
                    }}
                    className="button-30"
                    outlined
                  />
                </div> */}
              </Dialog>
            </div>
          </div>
          <Dialog
            header="Processing . . . "
            visible={showSpinner}
            showHeader={false}
            style={{ backgroundColor: "transparent" }}
          >
            <ProgressSpinner
              style={{ width: "50px", height: "50px" }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </Dialog>
          <Dialog
            header="Complaint Management"
            visible={submitAlert}
            style={{ backgroundColor: "transparent" }}
            onHide={() => {
              setSubmitAlert(false);
            }}
          >
            <div>
              <h1>Submitted Successfully!!</h1>
            </div>
            <br></br>
            <br></br>
            <Button
              label="Ok"
              className="Done-button"
              onClick={() => {
                setSubmitAlert(false);
                window.location.reload();
              }}
            />
          </Dialog>
        </div>
        <ConfirmDialog
          group="headless"
          visible={false}
          content={({ headerRef, contentRef, footerRef, hide, message }) => (
            <div className="flex flex-column align-items-center p-5 surface-overlay border-round">
              <div className="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i className=" text-5xl">📝</i>
              </div>
              <span
                className="font-bold text-1xl block mb-2 mt-4"
                ref={headerRef}
              >
                {lang("form.DetailConfirmation")}
              </span>
              <p className="mb-0" ref={contentRef}>
                {message.message}
              </p>
              <div
                className="flex align-items-center gap-2 mt-4"
                ref={footerRef}
              >
                <Button
                  label="Yes"
                  onClick={(event) => {
                    hide(event);
                    accept();
                  }}
                  className="w-8rem"
                ></Button>
                <Button
                  label="No"
                  outlined
                  onClick={(event) => {
                    hide(event);
                    reject();
                  }}
                  className="w-8rem"
                ></Button>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default WithoutUser;
