import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./LoginScreen.css";
import "primeicons/primeicons.css";
import { Toast } from "primereact/toast";
import LogoImage from "../Asset/Images/HTLI-LOGO.png";
import axios from "axios";
import { Dialog } from "primereact/dialog";
const LoginScreen = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    sessionStorage.setItem("username", "logout");
  }, []);
  useEffect(() => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
    } else if (isAndroid) {
      navigate("/qr_code");
    } else {
      alert("Platform not recognized.");
    }
  }, [navigate]);
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const footerContent = (
    <div>
      <div className="flex flex-wrap justify-content-center flex-grow">
        <div className="flex flex-wrap justify-content-center flex-grow">
          <div className="w-100 m-1 d-flex justify-content-end"></div>
          <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => {
              setVisible(false);
            }}
            style={{
              backgroundColor: "#025add",
              WebkitTextFillColor: "white",
            }}
            className="Done-button"
            autoFocus
            outlined
            rounded
          />
        </div>
      </div>
    </div>
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "") {
      setError("Please enter username");
      toast.current.show({
        severity: "error",
        summary: "Login Error",
        detail: "Please enter username",
      });
      return;
    } else if (password === "") {
      setError("Please enter password");
      toast.current.show({
        severity: "error",
        summary: "Login Error",
        detail: "Please enter password",
      });
      return;
    } else if (username === "" && password === "") {
      setError("Please enter both username and password.");
      toast.current.show({
        severity: "error",
        summary: "Login Error",
        detail: error,
      });
      return;
    } else {
      //   sessionStorage.setItem("username",username)
      //  sessionStorage.setItem("username", "username");
      // navigate("/reportofcomplients");

      axios
        .post("/test", {
          Usercode: username,
          Password: password,
        })
        .then((response) => {
          console.log("login", response);
          if (
            response.data[0].Status !== "404" &&
            response.data[0].Status !== false &&
            response.data[0].Username === username &&
            response.data[0].Password === password
          ) {
            sessionStorage.setItem("username", response.data[0].Username);
            navigate("/reportofcomplients");
          } else {
            toast.current.show({
              severity: "error",
              summary: "Login Error",
              detail: "Invalid Username or Password",
            });
          }
        })
        .catch((error) => {
          setError("Please enter Valid ");
          toast.current.show({
            severity: "error",
            summary: "Login Error",
            detail: error,
          });
          return;
        });
    }
  };
  const [capsLockOn, setCapsLockOn] = useState(false);

  // useEffect(() => {
  //   const handleKeyPress = (e) => {
  //     // Check if the CapsLock key is pressed
  //     const capsLockOn = e.getModifierState("CapsLock");
  //     setCapsLockOn(capsLockOn);
  //   };

  //   document.addEventListener("keydown", handleKeyPress);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);
  return (
    <div className="login-container ">
      <Toast ref={toast} position="bottom-left" />
      <div className="flex flex-wrap align-items-center justify-content-center text-left">
        <div
          className="flipleft animation-duration-1000 
        font-bold  border-round "
        >
          <div className="login-box ">
            <div className="login-box-inner">
              <img src={LogoImage} alt={"Login Logo"} className="login-logo" />
              <br />
              <br />
              <form onSubmit={handleSubmit}>
                <div className="p-field">
                  <span className="p-float-label">
                    <InputText
                      id="username"
                      type="text"
                      value={username}
                      onChange={handleUsernameChange}
                      className="Login-textBox"
                    />
                    <label
                      style={{ WebkitTextFillColor: "black" }}
                      htmlFor="username"
                    >
                      Username
                    </label>
                  </span>
                </div>
                <div className="p-field">
                  <span className="p-float-label">
                    <InputText
                      id="password"
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <label
                      style={{ WebkitTextFillColor: "black" }}
                      htmlFor="password"
                    >
                      Password
                    </label>
                  </span>
                </div>
                {capsLockOn ? (
                  <p style={{ WebkitTextFillColor: "orange" }}>
                    CapsLock is ON
                  </p>
                ) : (
                  <p></p>
                )}
                <Button
                  label="Login"
                  className="p-button-raised p-button-rounded button-login"
                />

                <p
                  style={{ textAlign: "center", WebkitTextFillColor: "black" }}
                >
                
                  <a style={{WebkitTextFillColor:"#55278c",cursor: "pointer"}} onClick={() => {setVisible(true)}}>
                    {" "}
                    <u>Forget password?  </u>
                  </a>
                
                </p>

                <p
                  style={{ textAlign: "center", WebkitTextFillColor: "black" }}
                >
                  Click here to get ➡️<a href="/qr_code"> QR Code</a>
                </p>
              </form>
              <Dialog
                header="ⓘ Information"
                headerClassName="category-header-style"
                visible={visible}
                draggable={false}
                style={{ width: "250px", border: "1px solid black" }}
                onHide={() => setVisible(false)}
                footer={footerContent}
              >
                <div className="terms-and-conditions">
                  <p>
                  Please contact the admin!
                  </p>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
