import React, { useEffect, useState, useRef } from "react";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";

const ReportDetails = (rowData) => {
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [submitAlert, setSubmitAlert] = useState(false);
  const ApiData = {
    formid: rowData.data.FORM_NUMBER,
  };
  const [FormDetails, setFormDetails] = useState();
  useEffect(() => {
    const fetchData = () => {
      axios
        .post("/reportdata", ApiData)
        .then((response) => {
          setFormDetails(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    };
    fetchData();
    const interval = setInterval(fetchData, 2000);
    return () => clearInterval(interval);
  }, []);
  const handleVerified = () => {
    setVisible(true);
    axios
      .post("/mail", {
        subject:
          "Urgent: Report of Suspected " +
          FormDetails[0].TYPE +
          " : " +
          FormDetails[0].FORM_NUMBER,
        body: `
      <html>
      <body style="font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0;">
        <div style="width: 80%; max-width: 800px; margin: 20px auto; padding: 20px; background-color: #ffffff; border-radius: 8px; box-shadow: 0 0 10px rgba(0,0,0,0.1);">
          <h2 style="text-align: center; color: #333;">${FormDetails[0].TYPE}</h2>
          <p style="text-align: center; color: #555;">Report Number: ${FormDetails[0].FORM_NUMBER}</p>

          <div style="margin: 20px 0; padding: 20px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ddd;">
            <h3 style="text-align: center; color: #333;">Basic Details</h3>
            <div style="margin-top: 20px;">
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">User Name:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].USERNAME}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Department Name:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].DEPARTMENTNAME}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Years Of Experience:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].YEARSOFEXP}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">(1). What improper activity occurred?</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].IMPROPERACTIVITY}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">(2). Who commited the activity?</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WHOCOMMITED}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">(3). When did you realize?</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WHENYOUREALIZED}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">(4). Can you Provide any evidence</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].EVIDANCE}</span>
              </p>
               <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Do you have any other details?</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].OTHERS}</span>
              </p>
            </div>
          </div>

          <div style="margin: 20px 0; padding: 20px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ddd;">
            <h3 style="text-align: center; color: #333;">Suspect Details</h3>
            <div style="margin-top: 20px;">
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Employee Name:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].SEMPLOYEENAME}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Employee ID:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].SEENO}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Department:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].SDEPARTMENT}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Contact Information:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].SCONTACT}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Description of Incident:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].HDETAILDESCRIPTION}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Evidence:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].HEVIDENCE}</span>
              </p>
            </div>
          </div>

          <div style="margin: 20px 0; padding: 20px; background-color: #f9f9f9; border-radius: 8px; border: 1px solid #ddd;">
            <h3 style="text-align: center; color: #333;">Witness Details</h3>
            <div style="margin-top: 20px;">
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Witness Name:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WEMPLOYEENAME}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Employee ID:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WEENO}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Department:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WDEPARTMENT}</span>
              </p>
              <p style="margin: 10px 0; color: #333;">
                <span style="display: inline-block; width: 30%; font-weight: bold;">Contact Information:</span>
                <span style="display: inline-block; width: 65%; background-color: #e0e0e0; color: #333; padding: 5px; border-radius: 4px; text-align: left;">${FormDetails[0].WCONTACT}</span>
              </p>
            </div>
          </div>

          <p style="color: #555;">
            This information is critical for investigating and addressing the reported incidents promptly. Your urgent attention to this matter is greatly appreciated.
          </p>

          <p style="color: #555;">
            Please let me know if you require any further information or assistance regarding this report.
          </p>

          <a href="http://cms.agaraminfotech.com/" style="display: block; width: 200px; margin: 20px auto; padding: 10px; text-align: center; color: #ffffff; background-color: #007bff; border-radius: 4px; text-decoration: none;">Visit CMS Portal</a>

          <p style="text-align: center; font-size: 0.8em; color: #999;">
            This email was generated by the reporting system and may contain sensitive information. Please handle with care.
          </p>
        </div>
      </body>
      </html>
    `,
        AttachmentFilePath: FormDetails[0].FILE_URL,
      })
      .then((response) => {
        setVisible(false);
        setSubmitAlert(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while saving data. Abuse form");
      });
  };
  const handleInvalid = () => {
    axios
      .post("/ApprovalReport", {
        formid: rowData.data.FORM_NUMBER,
        status: "Invalid",
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while fetching data.");
      });
  };
  const [imageUrl, setImageUrl] = useState("");
  const handleDownloadImg = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/DownloadFile",
        {
          AttachmentFilePath: FormDetails[0].FILE_URL.substring(
            FormDetails[0].FILE_URL.lastIndexOf("\\") + 1
          ),
        },
        {
          responseType: "blob", // Set response type to 'blob' to handle binary data
        }
      );

      if (response && response.data) {
        const objectUrl = URL.createObjectURL(response.data);
        setImageUrl(objectUrl);

        // Wait for the state to be updated before proceeding
        await new Promise((resolve) => setTimeout(resolve, 0));

        if (objectUrl) {
          const a = document.createElement("a");
          a.href = objectUrl;
          a.download = FormDetails[0].FILE_URL.substring(
            FormDetails[0].FILE_URL.lastIndexOf("\\") + 1
          );
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } else {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Complaint Management",
        //   detail: "File Doesn't Exist!!😢",
        // });
        alert("File Doesn't Exist!!😢");
      }
    } catch (error) {
      // toast.current.show({
      //   severity: "error",
      //   summary: "Complaint Management",
      //   detail: "File Doesn't Exist!!😢",
      // });
      console.error("Error fetching image:", error);
      alert("File Doesn't Exist!!😢");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="card flex justify-content-center">
        <div className="grid card flex justify-content-center"></div>
        {FormDetails && FormDetails.length > 0 && (
          <div className="card justify-content-center text-center">
            <div className="grid ">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <h1>
                    {"Ethics and Compliant : " + rowData.data.FORM_NUMBER || ""}
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid ">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Hemployeename"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].USERNAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Hemployeename"
                      type="text"
                      placeholder="Department"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].DEPARTMENTNAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="YearsOfExp"
                      type="text"
                      placeholder="YearsOfExp"
                      className="form-textbox"
                      name="YearsOfExp"
                      value={FormDetails[0].YEARSOFEXP}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Years of Experience
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Improperactivity"
                      value={FormDetails[0].IMPROPERACTIVITY}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (1). What improper activity occurred?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Whocommited"
                      value={FormDetails[0].WHOCOMMITED}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (2). Who committed the activity?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Whenyourealized"
                      value={FormDetails[0].WHENYOUREALIZED}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (3).When did you realize?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Evidance"
                      value={FormDetails[0].EVIDANCE}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (4).Can you provide any evidence?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Others"
                      value={FormDetails[0].OTHERS}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (5). Do you have any other details
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b>{FormDetails[0].TYPE}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Category"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Category"
                      value={FormDetails[0].CHOOSECATEGORY}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Category
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Hemployeename"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].HEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="HEVIDENCE"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="HEVIDENCE"
                      value={FormDetails[0].HEVIDENCE}
                      readOnly
                    ></InputText>

                    <label htmlFor="status" className="label-field">
                      Evidence
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="HDEPARTMENT"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="HDEPARTMENT"
                      value={FormDetails[0].HDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Others"
                      value={FormDetails[0].OTHERS}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Detail Description
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b>{"Suspect Information"}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="SEMPLOYEENAME"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="SDEPARTMENT"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="SEENO"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SEENO}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      EE No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="SCONTACT"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SCONTACT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Contact
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b>{"Witness Information"}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="WEMPLOYEENAME"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="WDEPARTMENT"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="WEENO"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WEENO}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      EE No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="WCONTACT"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WCONTACT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Contact
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  {"Evidance Document"}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <Button
                    className=" "
                    icon="pi pi-download"
                    label=" Document Download"
                    outlined
                    onClick={handleDownloadImg}
                  />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <Button
                    className="Verfication-button"
                    icon="pi pi-verified"
                    label="Verified"
                    onClick={handleVerified}
                  />
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <Button
                    className=" Invalid-button"
                    icon="pi pi-times-circle"
                    label="Not Valid"
                    onClick={handleInvalid}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        header="Processing . . . "
        visible={visible}
        className="spinner-style"
        onHide={() => {
          setVisible(false);
        }}
      >
        <ProgressSpinner
          style={{ width: "50px", height: "50px" }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
        <br />
        {"Mail Sending..."}
      </Dialog>
      <Dialog
        header="Complaint Management"
        visible={submitAlert}
        style={{ backgroundColor: "transparent" }}
        onHide={() => {}}
      >
        <div>
          <h1>Mail Sent Successfully!!!</h1>
        </div>
        <br></br>
        <br></br>
        <Button
          label="Ok"
          className="Done-button"
          onClick={() => {
            setSubmitAlert(false);
            axios
              .post("/ApprovalReport", {
                formid: rowData.data.FORM_NUMBER,
                status: "Verified",
              })
              .then((response) => {
                window.location.reload();
              })
              .catch((error) => {
                console.error("Error:", error);
                alert("An error occurred while fetching data.");
              });
            window.location.reload();
          }}
        />
      </Dialog>
    </>
  );
};
export default ReportDetails;
