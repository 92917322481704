import React, { useRef, useState, useEffect } from "react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../Asset/Css/Common.css";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import TopMenu from "../PageSetup/Tools/TopMenu";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { useTranslation } from "react-i18next";

const Bribery = () => {
  const toast = useRef(null);
  const headingRef = useRef(null);
  const navigate = useNavigate();
  const [sessionValue, setSessionValue] = useState([]);
  const [selectedCateGories, setCateGories] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [result, setResult] = useState();
  const [uploadFiles, setUploadFiles] = useState([]);
  const [SaveStatus, setSaveStatus] = useState("Loading. . .");
  const [submitAlert, setSubmitAlert] = useState(false);
  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.focus();
    }
    setSessionValue(JSON.parse(sessionStorage.getItem("form_one_data")));
  }, []);
  const fd = new FormData();

  const handleFileUpload = (e) => {
    const files = e.files;
    let fileTemp = [];
    for (let i = 0; i < files.length; i++) {
      fileTemp.push(files[i]);
    }
    setUploadFiles(fileTemp);
    for (const pair of fd.entries()) {
      console.log(pair[0] + ":" + pair[1]);
    }
  };
  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined hidden",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const cities = [
    { name: "Bribery", value: "Bribery" },
    { name: "Malicious actions", value: "Malicious actions" },
  ];

  const [formData, setFormData] = useState({
    Formoneid: "i",
    Choosecategory: "",
    Hemployeename: "",
    Hevidence: "",
    Hdepartment: "",
    Hdetaildescription: "",
    Semployeename: "",
    Seeno: "",
    Sdepartment: "",
    Scontact: "",
    Wemployeename: "",
    Weeno: "",
    Wdepartment: "",
    Wcontact: "",
    Createdby: "admin",
    Createdon: new Date().toISOString(),
    Modifiedby: "admin",
    Modifiedon: new Date().toISOString(),
  });

  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const isFormDataValid = () => {
    if (
      formData.Semployeename === "" ||
      formData.Seeno === "" ||
      formData.Sdepartment === "" ||
      formData.Scontact === "" //||
      // formData.Wemployeename === "" ||
      // formData.Weeno === "" ||
      // formData.Wdepartment === "" ||
      // formData.Wcontact === ""
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (isFormDataValid()) {
      uploadFiles.forEach((element) => {
        fd.append("files", element);
      });
      setShowSpinner(true);
      axios
        .post("/insertoneform", sessionValue)
        .then((response) => {})
        .catch((error) => {
          console.error("Error:", error); // Log error
          alert("An error occurred while saving data. head bri"); // Handle error case
        });
      axios
        .post("/briberyforminsert", formData) // Pass formData directly
        .then((response) => {
          setResult(response.data);
          console.log(result);
        })
        .catch((error) => {
          console.error("Error:", error); // Log error
          alert("An error occurred while saving data. child bri"); // Handle error case
        });
      setSaveStatus("File Uploading . . .");
      axios
        .post("/uploadBribery", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setSaveStatus("Form Data Saving . . .");
          console.log(response.data);
          setResult(response.data);
          setSubmitAlert(true);
          // axios
          //   .post("/mail", {
          //     subject: "Urgent: Report of Suspected Bribery",
          //     body: `
          //     Dear Management Team,
              
          //     I am writing to urgently address a matter of potential bribery within our organization. Attached is a comprehensive report detailing individuals involved, categorized as Suspects and Witnesses.
              
          //     For each Suspect, the report provides the following information:
          //     - Employee Name: ${formData.Semployeename}
          //     - Employee ID: ${formData.Seeno}
          //     - Department: ${formData.Sdepartment}
          //     - Contact Information: ${formData.Scontact}
          //     - Description of Incident: ${formData.Hdetaildescription}
          //     - Evidence: ${formData.Hevidence}
              
          //     Similarly, for each Witness, the report includes:
          //     - Witness Name: ${formData.Wemployeename}
          //     - Employee ID: ${formData.Weeno}
          //     - Department: ${formData.Wdepartment}
          //     - Contact Information: ${formData.Wcontact}
              
          //     It's crucial to investigate and address these reported incidents promptly. Your immediate attention to this matter is vital.
              
          //     If you need further information or assistance regarding this report, please don't hesitate to reach out.
              
          //     Sincerely,
          //     ${formData.Createdby}
          //     ${formData.Createdon}
              
          //     --
          //     This email was generated by the reporting system and may contain sensitive information. Please handle with care.
          // `,
          //     AttachmentFilePath: response.data, // Replace response.data with the actual file path
          //   }) // Pass formData directly
          //   .then((response) => {
            
          //   })
          //   .catch((error) => {
          //     console.error("Error:", error); // Log error
          //     alert("An error occurred while saving data. Abuse form"); // Handle error case
          //   });
        });
    }
    else{
      toast.current.show({
        severity: "error",
        summary: "Form Data Validation",
        detail: "Please Enter Suspect information",
      });
    }
  };

  const handleClear = () => {
    setFormData({
      Formoneid: "",
      Choosecategory: "",
      Hemployeename: "",
      Hevidence: "",
      Hdepartment: "",
      Hdetaildescription: "",
      Semployeename: "",
      Seeno: "",
      Sdepartment: "",
      Scontact: "",
      Wemployeename: "",
      Weeno: "",
      Wdepartment: "",
      Wcontact: "",
      Createdby: "admin",
      Createdon: new Date().toISOString(),
      Modifiedby: "admin",
      Modifiedon: new Date().toISOString(),
    });
  };
  const [lang, Langset] = useTranslation("global");
  return (
    <div className="container Header-main">
      <TopMenu />
      <Toast ref={toast} position="bottom-right" />
      <div className="">
        <div className="box-form">
          <div className="black-background font">
            <h3
              style={{ WebkitTextFillColor: "black", textAlign: "center" }}
              tabIndex={0}
              ref={headingRef}
            >
              {lang("listOfComplaints.bribery")}
            </h3>
            <hr />
            <div className="">
              <div className="grid">
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <Dropdown
                        value={selectedCateGories}
                        onChange={(e) => {
                          setCateGories(e.target.value);
                          handleChange(e);
                        }}
                        options={cities}
                        optionLabel="value"
                        placeholder="Choose Category"
                        maxSelectedLabels={3}
                        className="form-MultiSelect"
                        name="Choosecategory"
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.chooseCategory")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Employee Name/No"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Hemployeename"
                        value={formData.Hemployeename}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.eName")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Evidence"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Hevidence"
                        value={formData.Hevidence}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.evidence")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Department"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Hdepartment"
                        value={formData.Hdepartment}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.department")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputTextarea
                        autoResize
                        rows={5}
                        className="form-MultiSelect"
                        cols={10}
                        onChange={(e) => handleChange(e)}
                        name="Hdetaildescription"
                        value={formData.Hdetaildescription}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.detailDescription")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <FileUpload
                        multiple
                        emptyTemplate={
                          <p className="m-0">
                            Drag and drop files to here to upload.<br></br>
                            Max. File Size 10 mb
                          </p>
                        }
                        cancelOptions={cancelOptions}
                        uploadOptions={uploadOptions}
                        chooseOptions={chooseOptions}
                        onSelect={handleFileUpload}
                        maxFileSize={10485760}
                      />
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <h3 style={{ textAlign: "center" }}>
                      {lang("complaintForm.sHeader")}
                     
                    </h3>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Employee Name"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Semployeename"
                        value={formData.Semployeename}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.SeName")}
                        <span
                          style={{
                            WebkitTextFillColor: "red",
                            fontSize: "17px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="EE No"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Seeno"
                        value={formData.Seeno}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.sENo")}
                        <span
                          style={{
                            WebkitTextFillColor: "red",
                            fontSize: "17px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Department"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Sdepartment"
                        value={formData.Sdepartment}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.sDepartment")}
                        <span
                          style={{
                            WebkitTextFillColor: "red",
                            fontSize: "17px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Contact"
                        className="form-textbox"
                        keyfilter="int"
                        onChange={(e) => handleChange(e)}
                        maxLength={10}
                        name="Scontact"
                        value={formData.Scontact}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.sContact")}
                        <span
                          style={{
                            WebkitTextFillColor: "red",
                            fontSize: "17px",
                          }}
                        >
                          *
                        </span>
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <h3 style={{ textAlign: "center" }}>
                      {lang("complaintForm.wHeader")}
                    </h3>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Employee Name"
                        name="Wemployeename"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        value={formData.Wemployeename}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.WeName")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="EE No"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Weeno"
                        value={formData.Weeno}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.wENo")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Department"
                        className="form-textbox"
                        onChange={(e) => handleChange(e)}
                        name="Wdepartment"
                        value={formData.Wdepartment}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.wDepartment")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
                <div className="field col-3">
                  <div className="card justify-content-center">
                    <FloatLabel>
                      <InputText
                        id="status"
                        type="text"
                        placeholder="Contact"
                        className="form-textbox"
                        keyfilter="int"
                        maxLength={10}
                        onChange={(e) => handleChange(e)}
                        name="Wcontact"
                        value={formData.Wcontact}
                      />
                      <label htmlFor="status" className="label-field">
                        {lang("complaintForm.wContact")}
                      </label>
                    </FloatLabel>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-content-center flex-grow">
              <div className="flex flex-wrap justify-content-center flex-grow">
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-arrow-right"
                    rounded
                    outlined
                    label="Save"
                    className="Submit-button"
                    onClick={(e) => handleSave(e)}
                  />
                </div>
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    aria-label="Next"
                    label="Clear"
                    className="Clear-button"
                    onClick={handleClear}
                  />
                </div>
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-arrow-left"
                    rounded
                    outlined
                    aria-label="Next"
                    label="Back"
                    className="Back-button"
                    onClick={() => navigate("/form")}
                  />
                </div>
              </div>
            </div>
            <Dialog
              header="Processing . . . "
              visible={showSpinner}
              style={{ backgroundColor: "transparent" }}
              showHeader="false"
            >
              <ProgressSpinner
                style={{ width: "50px", height: "50px" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
              {SaveStatus}
            </Dialog>
          </div>
          <Dialog
            header="Complaint Management"
            visible={submitAlert}
            style={{ backgroundColor: "transparent" }}
            onHide={() => {}}
          >
            <div>
              <h1>Submitted Successfully!!</h1>
            </div>
            <br></br>
            <br></br>
            <Button
              label="Ok"
              className="Done-button"
              onClick={() => {
                setSubmitAlert(false);
                navigate("/");
                window.location.reload();
              }}
            />
          </Dialog>
        </div>
      </div>
    </div>
  );
};
export default Bribery;
