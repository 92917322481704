import React, { useState, useEffect } from 'react';

const IpFy = () => {
  const [ipAddress, setIPAddress] = useState(null);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api6.ipify.org?format=json');
        const data = await response.json();
        setIPAddress(data.ip);
      } catch (error) {
        console.error('Error fetching IPv6 address:', error);
      }
    };

    fetchIPAddress();
  }, []);

  return (
    <div>
      <h1>Client IPv6 Address:</h1>
      <p>{ipAddress}</p>
    </div>
  );
};

export default IpFy;
