import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../Asset/Css/Common.css";
import { Dialog } from "primereact/dialog";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import imageLogo from "../../Asset/Images/HTLI-LOGO.png";

const TopMenu = () => {
  const history = useNavigate();
  const toast = useRef(null);
  const menuRef = useRef(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      // if (sessionStorage.getItem("username") !== null) {
      history("/login");
      // }
    } else if (isAndroid) {
      {
        if (sessionStorage.getItem("Passcode") !== "Entered") {
          history("/");
        }
      }
    } else {
    }
  }, [history]);
  const items = [
    {
      label: (
        <img
          src={imageLogo}
          alt="Company Logo"
          sizes="80px"
          className="company-logo"
        />
      ),
    },
  ];
  const [search, setSearch] = useState("");

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.label === "Log Out") {
      sessionStorage.setItem("username", "logout");
      history("/");
    } else if (menuItem.label === "User Creation") {
      history("/usercreation");
    } else if (menuItem.label === "Dashboard") {
      history("/dashboard");
    } else if (menuItem.label === "User Profile") {
      toast.current.show({
        severity: "info",
        summary: "User Profile",
        detail: "Anonymous",
        life: 3000,
        position: "bottom-left",
      });
    }
  };

  const SplitItem = [
    {
      label: "User Creation",
      icon: PrimeIcons.USER_PLUS,
      command: () => handleMenuItemClick({ label: "User Creation" }), // Command for logout item
    },
  ];
  const handleClick = () => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      const recipient = "manianuram2312@gmail.com"; // Replace with the desired recipient email address
      const subject = encodeURIComponent("Need for Support"); // Optional: Set the email subject
      const body = encodeURIComponent("Body of the email"); // Optional: Set the email body
      const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${recipient}&su=${subject}&body=${body}`;
      window.open(gmailComposeLink);
    } else if (isAndroid) {
      window.location.href = "tel:7904544330";
    } else {
      alert("Platform not recognized.");
    }
  };

  const endItems = [
    <Button
      key="splitButton"
      icon="pi pi-user"
      className="p-button-icon-form"
      model={SplitItem}
      onClick={(e) => handleMenuItemClick({ label: "User Profile" })}
      rounded
      outlined
    />,
  ];

  return (
    <div>
      <Menubar
        menuRef={menuRef}
        start={
          <img
            src={items[0].label.props.src}
            alt={items[0].label.props.alt}
            sizes={items[0].label.props.sizes}
            className={items[0].label.props.className}
          />
        }
        end={endItems}
        className="custom-menubar"
        outlined
      />

      <Toast ref={toast} position="bottom-right" />
    </div>
  );
};

export default TopMenu;
