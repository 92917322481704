import "./App.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { useEffect, useCallback } from "react";
import LoginScreen from "./Auth/LoginScreen";
import WithoutUser from "./Without/FormOne";
import Abuse from "./Without/Abuse";
import Bribery from "./Without/Bribery";
import Harrashment from "./Without/Harassment";
import Unethical from "./Without/Unethical";
import QR from "./PageSetup/Tools/QR";
import ReportOfComplients from "./WithAuth/Report";
import UserCreation from "./Auth/UserCreation";
import Support from "./PageSetup/Tools/Support";
import Test from "./PageSetup/Tools/QRPrinter";
import Dialor from "./PageSetup/Tools/PhoneDialor";
import DownloadButton from "./PageSetup/Tools/QrDownload";
import FileUpload from "./PageSetup/Tools/upload";
import TemplateDemo from "./PageSetup/Tools/FileUpload";
import ApprovalReport from "./WithAuth/ApprovalReport";
import RejectionReport from "./WithAuth/RejectionReport";
import FileDownloader from "./Without/File";
import ImageComponent from "./PageSetup/Tools/test";
import PassCode from "./Without/Passcode";
import Key from "./PageSetup/SupportKey";
import IpFy from "./PageSetup/Tools/IpFinder";
import DashBoard from "./WithAuth/DashBoard";
import DashBoardGrid from "./WithAuth/DashBoardGrid";

const App = () => {
  const handleKeyPress = useCallback((event) => {
    if (event.key === "F1") {
      window.open("https://google.com", "_blank");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);
  return (
    <>
      <div>
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<PassCode />} />
            <Route exact path="/dashgrid" element={<DashBoardGrid />} />
            <Route exact path="/qr_code" element={<QR />} />
            <Route exact path="/k" element={<Key />} />
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/form" element={<WithoutUser />} />
            <Route exact path="/abuse" element={<Abuse />} />
            <Route exact path="/bribery" element={<Bribery />} />
            <Route exact path="/harrashment" element={<Harrashment />} />
            <Route exact path="/unethical" element={<Unethical />} />
            <Route exact path="/ip" element={<IpFy />} />
            <Route
              exact
              path="/reportofcomplients"
              element={<ReportOfComplients />}
            />
            <Route exact path="/usercreation" element={<UserCreation />} />
            <Route exact path="/support" element={<Support />} />
            <Route exact path="/test" element={<Test />} />
            <Route exact path="/dialor" element={<Dialor />} />
            <Route exact path="/qrcode" element={<DownloadButton />} />
            <Route exact path="/upload" element={<FileUpload />} />
            <Route exact path="/fileupload" element={<TemplateDemo />} />
            <Route exact path="/ApprovalReport" element={<ApprovalReport />} />
            <Route exact path="/RejectionReport" element={<RejectionReport />}/>
            <Route exact path="/fdwn" element={<FileDownloader />} />
            <Route exact path="/img" element={<ImageComponent />} />
            <Route exact path="/DashBoard" element={<DashBoard/>}/>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
