import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TopMenuAuth from "../PageSetup/Tools/TopMenu_Auth";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import "../Asset/Css/Common.css";
import axios from "axios";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import ReportDetails from "./ReportDetails";
import ApprovalDetails from "./ApprovalDetails";
import { useLocation, useNavigate } from "react-router-dom";


const DashBoardGrid = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const onRowSelect = (event) => {
   
    setSelectedRowData(event.data);
    console.log(event.data)
    setIcon("pi pi-eye");
    setVisible(true);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const toast = useRef(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [GridValue, setGridValue] = useState([]);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          outlined
          className="render-clear-button-custom-datatable "
          onClick={(e)=>{ onGlobalFilterChange(e);}}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="keyword-search-grid"
          />
        </span>
      </div>
    );
  };
  const [DataReports, setDataReports] = useState({});
  const fetchData = (endpoint) => {
    axios
      .post(endpoint, {
        state: location.state.state,
        type: location.state.type,
      })
      .then((response) => {
        setDataReports(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while fetching data.");
      });
  };

  useEffect(() => {
    if (location.state && location.state.state) {
      const stateLower = location.state.state.toLowerCase();
      const endpointMap = {
        all: "/dashgrid_all",
        approval: "/dashgrid_Approval",
        reject: "/dashgrid_Reject",
        notprocess: "/dashgrid_NotProcess",
      };

      const endpoint = endpointMap[stateLower];
      if (endpoint) {
        fetchData(endpoint);
        const interval = setInterval(() => fetchData(endpoint), 2000);
        return () => clearInterval(interval);
      }
    }
  }, [location]);

  const handleExport = () => {
    const dataToExport = GridValue.map((item) => ({
      usercode: item.usercode,
      username: item.username,
      status: item.status,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "data.xlsx");
  };
  const handleRefresh = () => {
    axios
      .get("/init_usercreation")
      .then((response) => {
        setGridValue(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while saving data.");
      });
  };
  const paginatorLeft = (
    <Button
      type="button"
      icon="pi pi-refresh"
      className="paginator-button"
      onClick={() => {
        handleRefresh();
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Page Refreshed",
          life: 3000,
        });
      }}
      text
    />
  );
  const paginatorRight = (
    <Button
      type="button"
      icon="pi pi-download"
      className="paginator-button hidden"
      onClick={handleExport}
      text
    />
  );
  const [popupData, setPopupData] = useState();
  const handleRow = (rowData) => {
    setPopupData(rowData.FORMONEID);
  };
  const [icon, setIcon] = useState("pi pi-eye-slash");
  return (
    <div className="container-windows">
      <Toast ref={toast} />
      <TopMenuAuth />
      <div className="">
        <div className="box-form-windows">
          <div className="black-background  font">
            <h2
              style={{
                WebkitTextFillColor: "black",
                textAlign: "center",
                fontSize: "x-large",
              }}
              tabIndex={0}
              className="report-header"
            >
              Dashboard GridDetails
            </h2>
            <br />
            <div className="grid">
              <div className="field col-offset-10 ">
                <div className="card  justify-content-start">
                  <Button label="   Back to DashBoard" className="Submit-button pi pi-arrow-circle-left" onClick={()=>{navigate("/dashboard")}}/>
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={DataReports.complaint}
                paginator
                rows={5}
                filters={filters}
                showGridlines
                rowsPerPageOptions={[5, 10, 25, 50]}
                header={renderHeader()}
                globalFilterFields={[
                  "FORM_NUMBER",
                  "ApprovalStatus",
                  "FCREATEDON",
                ]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                className="custom-datatable"
                selectionMode="single"
                selection={selectedRowData}
                onRowClick={onRowSelect}
                rowClassName={rowData => {
                  return rowData.IsRead.toLowerCase() === "pi pi-eye-slash" ? 'row-red row-style-eye-slash' : 'row-white row-style-eye';
                }}
                
              >
                <Column
                  style={{ width: "2rem" }}
                  body={(rowData) => (
                    <div
                      className="flex justify-between"
                      style={{ margin: "0.5px" }}
                    >
                      <Button
                        className="popup-button"
                        icon="pi pi-external-link"
                        onClick={() => {
                          handleRow(rowData);
                          setSelectedRowData(rowData);
                          setIcon("pi pi-eye");
                          setVisible(true);
                        }}
                      />
                        <Button
                          className="popup-button"
                          icon={rowData.IsRead.toLowerCase()}
                          onClick={() => {
                            console.log(rowData);
                          }}
                        />
                    </div>
                  )}
                />

                <Column
                  field="FORM_NUMBER"
                  header="Complaint Code"
                  filterPlaceholder="Search by FORM_NUMBER"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  field="ApprovalStatus"
                  header="Approval Status"
                  filterPlaceholder="Search by APPROVALSTATUS"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  field="FCREATEDON"
                  header="Created Date and Time"
                  filterPlaceholder="Search by createdon"
                  style={{ minWidth: "12rem" }}
                />
                {/* <Column
                  field="ApprovalStatus"
                  header="Created Date and Time"
                  filterPlaceholder="Search by ApprovalStatus"
                  style={{ minWidth: "12rem" }}
                /> */}
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={"Details About Complaint : "}
        visible={visible}
       
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        {sessionStorage.getItem("username") === "superadmin" ? (
          <ApprovalDetails data={selectedRowData} />
        ) : (
          <ApprovalDetails data={selectedRowData} />
        )}
      </Dialog>
    </div>
  );
};

export default DashBoardGrid;
