import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles([...selectedFiles, ...event.target.files]);
  };

  const handleUpload = async () => {
    try {
      const fd = new FormData();
      selectedFiles.forEach((file, index) => {
        fd.append(`file${index + 1}`, file);
      });
      await axios.post('/upload', fd, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Files uploaded successfully');
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} multiple />
      <button onClick={handleUpload}>Upload</button>
    </div>
  );
};

export default FileUpload;
