import React, { useEffect, useState, useRef } from "react";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import axios from "axios";
import { Toast } from "primereact/toast";

const ApprovalDetails = (rowData) => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const ApiData = {
    formid: rowData.data.FORM_NUMBER,
  };
  const [FormDetails, setFormDetails] = useState();
  useEffect(() => {
    axios
      .post("/reportdata", ApiData)
      .then((response) => {
        setFormDetails(response.data);
        console.log("details", response.data[0]);
        console.log("FormDetails", FormDetails[0].FORM_NUMBER);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const [imageUrl, setImageUrl] = useState("");
  const handleDownloadImg = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/DownloadFile",
        {
          AttachmentFilePath: FormDetails[0].FILE_URL.substring(
            FormDetails[0].FILE_URL.lastIndexOf("\\") + 1
          ),
        },
        {
          responseType: "blob", // Set response type to 'blob' to handle binary data
        }
      );

      if (response && response.data) {
        const objectUrl = URL.createObjectURL(response.data);
        setImageUrl(objectUrl);

        // Wait for the state to be updated before proceeding
        await new Promise((resolve) => setTimeout(resolve, 0));

        if (objectUrl) {
          const a = document.createElement("a");
          a.href = objectUrl;
          a.download = FormDetails[0].FILE_URL.substring(
            FormDetails[0].FILE_URL.lastIndexOf("\\") + 1
          );
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      } else {
        // toast.current.show({
        //   severity: "error",
        //   summary: "Complaint Management",
        //   detail: "File Doesn't Exist!!😢",
        // });
        alert("File Doesn't Exist!!😢");
      }
    } catch (error) {
      // toast.current.show({
      //   severity: "error",
      //   summary: "Complaint Management",
      //   detail: "File Doesn't Exist!!😢",
      // });
      console.error("Error fetching image:", error);
      alert("File Doesn't Exist!!😢");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="card flex justify-content-center">
        <div className="grid card flex justify-content-center"></div>
        {FormDetails && FormDetails.length > 0 && (
          <div className="card justify-content-center text-center">
            <div className="grid ">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <h1>
                    {"Ethics and Compliant : " + rowData.data.FORM_NUMBER || ""}
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid ">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].USERNAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Department"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].DEPARTMENTNAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="YearsOfExp"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].YEARSOFEXP}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Years of Experience
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>

            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Improperactivity"
                      value={FormDetails[0].IMPROPERACTIVITY}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (1). What improper activity occurred?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Whocommited"
                      value={FormDetails[0].WHOCOMMITED}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (2). Who committed the activity?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Whenyourealized"
                      value={FormDetails[0].WHENYOUREALIZED}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (3).When did you realize?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Evidance"
                      value={FormDetails[0].EVIDANCE}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (4).Can you provide any evidence?
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Others"
                      value={FormDetails[0].OTHERS}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      (5). Do you have any other details
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b>{FormDetails[0].TYPE}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].CHOOSECATEGORY}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Category
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].HEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].HEVIDENCE}
                      readOnly
                    ></InputText>

                    <label htmlFor="status" className="label-field">
                      Evidence
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].HDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      rows={5}
                      className="form-MultiSelect"
                      cols={10}
                      name="Others"
                      value={FormDetails[0].OTHERS}
                      readOnly
                 
                    />
                    <label htmlFor="status" className="label-field">
                      Detail Description
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b> {"Suspect Information"}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SEENO}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      EE No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].SCONTACT}
                      readOnly
                      invalid
                    />
                    <label htmlFor="status" className="label-field">
                      Contact
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <b> {"Witness Information"}</b>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WEMPLOYEENAME}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Employee Name/No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WDEPARTMENT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Department
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WEENO}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      EE No
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-4">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="status"
                      type="text"
                      placeholder="Employee Name/No"
                      className="form-textbox"
                      name="Hemployeename"
                      value={FormDetails[0].WCONTACT}
                      readOnly
                    />
                    <label htmlFor="status" className="label-field">
                      Contact
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  {"Evidance Document"}
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="field col-4">
                <div className="card justify-content-center text-center">
                  <Button
                    icon="pi pi-download"
                    label=" Document Download"
                    onClick={handleDownloadImg}
                    outlined
                  />
                  <i
                    className={` ${loading ? "pi pi-spin pi-sync" : ""}`}
                    style={{ fontSize: "2rem" }}
                  ></i>
                </div>
              </div>
            </div>
            {
              <div className="grid">
                <div className="field col-4">
                  <div className="card justify-content-center text-center">
                    <Button
                      className="Invalid-button"
                      icon="pi pi-times-circle"
                      label=" Close"
                      onClick={() => {
                        window.location.reload();
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
};
export default ApprovalDetails;
