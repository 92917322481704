import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { I18nextProvider } from "react-i18next"; 
import i18next from "i18next"; 
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import global_en from "./Language/EN/global.json";
import global_es from "./Language/TN/global.json";
import { PrimeReactProvider } from "primereact/api";
i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    tn: {
      global: global_es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
// axios.defaults.baseURL = 'http://localhost:5176/';//Local
//  axios.defaults.baseURL = 'http://192.168.0.146:8003/';//Local
// axios.defaults.baseURL = 'http://192.168.0.108:8003/';//Client
// axios.defaults.baseURL = "http://192.168.0.136:8003"; //Livecmsservice@agaraminfotech.com
axios.defaults.baseURL = "http://cmsservice.agaraminfotech.com/"; //Live

root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </PrimeReactProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
