import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import TopMenuAuth from "../PageSetup/Tools/TopMenu_Auth";
import { use } from "i18next";
import axios from "axios";
import "../Asset/Css/Common.css";
import { Chart } from "primereact/chart";
import Harassment from "../Without/Harassment";
import { useNavigate } from "react-router-dom";
import DashBoardGrid from "./DashBoardGrid";
import { Button } from "primereact/button";

const DashBoard = () => {
  const Navigate = useNavigate();
  const toast = useRef(null);
  const [chartData, setChartData] = useState({});
  const [piechartData, setPieChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [piechartOptions, setPieChartOptions] = useState({});

  const [GridValue, setGridValue] = useState();
  const [divData, setDivData] = useState({
    Unethical: "",
    Abuse: "",
    Bribery: "",
    Harassment: "",
    Others: "",
  });
  const [ApprovalData, setApprovalData] = useState({
    Unethical: "",
    Abuse: "",
    Bribery: "",
    Harassment: "",
    Others: "",
  });
  const [RejectData, setRejectData] = useState({
    Unethical: "",
    Abuse: "",
    Bribery: "",
    Harassment: "",
    Others: "",
  });
  const [NotProcessData, setNotProcessData] = useState({
    Unethical: "",
    Abuse: "",
    Bribery: "",
    Harassment: "",
    Others: "",
  });
  const Data = {};
  useEffect(() => {
    if (sessionStorage.getItem("username") !== "superadmin") {
      Navigate("/login");
    }
  });
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    // Fetch data from the server
    axios
      .get("/dashboard")
      .then((response) => {
        // Update state with the fetched data
        console.log("Log Data", response.data);

        setDivData({
          Unethical: response.data[0].Unethical,
          Abuse: response.data[0].Abuse,
          Bribery: response.data[0].Bribery,
          Harassment: response.data[0].Harassment,
          Others: response.data[0].Others,
        });
        setApprovalData({
          Unethical: response.data[1].Unethical,
          Abuse: response.data[1].Abuse,
          Bribery: response.data[1].Bribery,
          Harassment: response.data[1].Harassment,
          Others: response.data[1].Others,
        });
        setRejectData({
          Unethical: response.data[2].Unethical,
          Abuse: response.data[2].Abuse,
          Bribery: response.data[2].Bribery,
          Harassment: response.data[2].Harassment,
          Others: response.data[2].Others,
        });
        setNotProcessData({
          Unethical: response.data[3].Unethical,
          Abuse: response.data[3].Abuse,
          Bribery: response.data[3].Bribery,
          Harassment: response.data[3].Harassment,
          Others: response.data[3].Others,
        });
        const data = {
          labels: ["Unethical", "Abuse", "Bribery", "Harassment"],
          datasets: [
            {
              data: [
                response.data[0].Unethical,
                response.data[0].Abuse,
                response.data[0].Bribery,
                response.data[0].Harassment,
              ], // Assuming response data has properties pending, reject, and approve
              backgroundColor: [
                documentStyle.getPropertyValue("--blue-500"),
                documentStyle.getPropertyValue("--red-500"),
                documentStyle.getPropertyValue("--green-500"),
                documentStyle.getPropertyValue("--orange-500"),
              ],
              hoverBackgroundColor: [
                documentStyle.getPropertyValue("--blue-400"),
                documentStyle.getPropertyValue("--red-400"), // Corrected the color here
                documentStyle.getPropertyValue("--green-400"),
                documentStyle.getPropertyValue("--orange-400"),
              ],
            },
          ],
        };
        // Set the chart data
        setChartData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while fetching data.");
      });
  }, []);
  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    axios
      .get("/dashboard")
      .then((response) => {
        const data = {
          labels: ["Unethical", "Abuse", "Bribery", "Harassment"],
          datasets: [
            {
              data: [
                response.data[0].Unethical,
                response.data[0].Abuse,
                response.data[0].Bribery,
                response.data[0].Harassment,
              ],
              backgroundColor: [
                documentStyle.getPropertyValue("--blue-500"),
                documentStyle.getPropertyValue("--red-500"),
                documentStyle.getPropertyValue("--green-500"),
                documentStyle.getPropertyValue("--orange-500"),
              ],
              hoverBackgroundColor: [
                documentStyle.getPropertyValue("--blue-400"),
                documentStyle.getPropertyValue("--red-400"), // Corrected the color here
                documentStyle.getPropertyValue("--green-400"),
                documentStyle.getPropertyValue("--orange-400"),
              ],
            },
          ],
        };

        const options = {
          cutout: "60%",
        };

        setPieChartData(data);
        setPieChartOptions(options);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while fetching data.");
      });
  }, []);

  return (
    <div className="container-windows">
      <Toast ref={toast} />
      <TopMenuAuth />
      <div className="">
        <div className="box-form-windows">
          <div className="black-background  font">
            <h2
              style={{
                WebkitTextFillColor: "black",
                textAlign: "center",
                fontSize: "x-large",
              }}
              tabIndex={0}
              className="report-header"
            >
              DashBoard
            </h2>
            <br />
            <br />
       
            {/* <div className="grid bg-test text-center">
              <div className="field col-3">
                <div className="  justify-content-start">
                  <Chart
                    type="pie"
                    data={piechartData}
                    options={piechartOptions}
                    className=" md:w-25rem"
                  />
                </div>
              </div>
              <div className=" col-6">
                <div className="  justify-content-end">
                  <Chart
                    type="bar"
                    data={chartData}
                    options={chartOptions}
                    className=" md:w-35rem"
                  />
                </div>
              </div>
            </div> */}
            <h1
              style={{ WebkitTextFillColor: "black", fontSize: "medium" }}
              className="text-center"
            >
              Over All Complaints
            </h1>
            <div className="grid bg-test text-center">
              <div
                className=" bgtest-col"
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "unethical", state: "all" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Unethical</u>
                </h4>
                <p>{divData.Unethical}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "bribery", state: "all" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Bribery</u>
                </h4>
                <p>{divData.Bribery}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "abuse", state: "all" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Abuse</u>
                </h4>
                <p>{divData.Abuse}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "harassment", state: "all" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Harassment</u>
                </h4>
                <p>{divData.Harassment}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "others", state: "all" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Others</u>
                </h4>
                <p>{divData.Others}</p>
              </div>
            </div>
            <h1
              style={{ WebkitTextFillColor: "black", fontSize: "medium" }}
              className="text-center"
            >
              Approval Complaints
            </h1>
            <div className="grid bg-Approval text-center">
              <div
                className=" bgtest-col"
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "unethical", state: "approval" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Unethical</u>
                </h4>
                <p>{ApprovalData.Unethical}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "bribery", state: "approval" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Bribery</u>
                </h4>
                <p>{ApprovalData.Bribery}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "abuse", state: "approval" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Abuse</u>
                </h4>
                <p>{ApprovalData.Abuse}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "harassment", state: "approval" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Harassment</u>
                </h4>
                <p>{ApprovalData.Harassment}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "others", state: "approval" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Others</u>
                </h4>
                <p>{ApprovalData.Others}</p>
              </div>
            </div>
            <h1
              style={{ WebkitTextFillColor: "black", fontSize: "medium" }}
              className="text-center"
            >
              Reject Complaints
            </h1>
            <div className="grid bg-Reject text-center">
              <div
                className=" bgtest-col"
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "unethical", state: "reject" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Unethical</u>
                </h4>
                <p>{RejectData.Unethical}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "bribery", state: "reject" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Bribery</u>
                </h4>
                <p>{RejectData.Bribery}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "abuse", state: "reject" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Abuse</u>
                </h4>
                <p>{RejectData.Abuse}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "harassment", state: "reject" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Harassment</u>
                </h4>
                <p>{RejectData.Harassment}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "others", state: "reject" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Others</u>
                </h4>
                <p>{RejectData.Others}</p>
              </div>
            </div>
            <h1
              style={{ WebkitTextFillColor: "black", fontSize: "medium" }}
              className="text-center"
            >
              Not Process Complaints
            </h1>
            <div className="grid bg-not-process text-center">
              <div
                className=" bgtest-col"
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "unethical", state: "notprocess" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Unethical</u>
                </h4>
                <p>{NotProcessData.Unethical}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "bribery", state: "notprocess" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Bribery</u>
                </h4>
                <p>{NotProcessData.Bribery}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "abuse", state: "notprocess" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Abuse</u>
                </h4>
                <p>{NotProcessData.Abuse}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "harassment", state: "notprocess" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Harassment</u>
                </h4>
                <p>{NotProcessData.Harassment}</p>
              </div>
              <div
                className=" bgtest-col "
                onClick={() => {
                  Navigate("/dashgrid", {
                    state: { type: "others", state: "notprocess" },
                  });
                }}
              >
                <h4 style={{ WebkitTextFillColor: "black" }}>
                  <u>Others</u>
                </h4>
                <p>{NotProcessData.Others}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashBoard;
