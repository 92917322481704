import React ,{ useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Dialor =()=>
{
    const navigate = useNavigate();
    useEffect(()=>{
        const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
        const isAndroid = window.navigator.userAgent
          .toLowerCase()
          .includes("android");
        if (isWindows) {
            navigate("/login");
        } else if (isAndroid) {
          window.location.href = "tel:7904544330";
        } else {
          alert("Platform not recognized.");
        }
  
    },[navigate]);
    return(<>
    <div className="container-dialer">

    </div>
    </>);
}
export default Dialor;