import React, { useState } from 'react';
import axios from 'axios';

const ImageComponent = () => {
    const [imageUrl, setImageUrl] = useState('');

    const getImage = async () => {
        try {
            const response = await axios.get('/DownloadFile', {
                responseType: 'blob' // Set response type to 'blob' to handle binary data
            });
            const objectUrl = URL.createObjectURL(response.data);
            setImageUrl(objectUrl);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const handleDownloadClick = () => {
        if (imageUrl) {
            const a = document.createElement('a');
            a.href = imageUrl;
            a.download = 'image.png'; // Set the desired filename here
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    return (
        <div>
            <button onClick={getImage}>Get Image</button>
            {imageUrl && <img src={imageUrl} alt="Image" />}
            {imageUrl && <button onClick={handleDownloadClick}>Download Image</button>}
        </div>
    );
};

export default ImageComponent;
