import React from 'react';
import axios from 'axios';

class FileDownloader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileUrl: 'D:\\ComplaintManagement\\service\\cms_service\\publish_live_test/UploadedFiles/Unethical\\17146435517561104573128663965404_20240502152300119.jpg'
    };
  }

  handleDownload = () => {
    axios({
      url: this.state.fileUrl,
      method: 'GET',
      responseType: 'blob', // Important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filename.jpg');
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    return (
      <div>
        <button onClick={this.handleDownload}>Download File</button>
      </div>
    );
  }
}

export default FileDownloader;
