import React, { useRef, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const PassCode = () => {
  const op = useRef(null);
  const [passCode, setPasscode] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      // if (sessionStorage.getItem("username") !== null) {
      navigate("/login");
      // }
    } else if (isAndroid) {
      {
        navigate("/");
      }
    } else {
    }
  }, [navigate]);
  sessionStorage.removeItem("Passcode");
  return (
    <>
      <div>
        <div className="container-Encrypt">
          <div
            className="login-form"
            style={{
              backgroundColor: "#014fc3",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1 style={{ textAlign: "center", color: "white" }}>
              Enter the Passcode
            </h1>
            <InputText
              type="text"
              className="form-textbox"
              onChange={(e) => {
                console.log(e.target.value);
                if (e.target.value === "cms") {
                  sessionStorage.setItem("Passcode", "Entered");
                  console.log("done");
                  setPasscode(false);
                  navigate("/form");
                }
              }}
              name="Username"
              invalid
              placeholder="Passcode"
            />
            <Button
              type="button"
              icon="pi pi-exclamation-circle"
              onClick={(e) => op.current.toggle(e)}
              outlined
              style={{ WebkitTextFillColor: "white" }}
              autoFocus
            />
            <div className="card flex justify-content-center">
              <OverlayPanel ref={op}>
                <p>
                  This passcode validates HTLI employees and does not store any
                  personal details.
                </p>
              </OverlayPanel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PassCode;
