import React from 'react';

const DownloadButton = () => {
  const handleDownload = () => {
    // HTML content to be downloaded
    const htmlContent = `
     
    `;
    
    // Create a Blob object with the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'downloaded_file.html'); // Adjust filename as needed

    // Simulate a click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <button onClick={handleDownload}>Download HTML File</button>
  );
};

export default DownloadButton;
