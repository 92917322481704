import React, { useState } from "react";
import "../../Asset/Css/Common.css";
import TopMenuAuth from "./TopMenu_Auth";
import techsupport from "../../Asset/Images/techsupports.png";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { Avatar } from "primereact/avatar";
import agaramLogo from "../../Asset/Images/favicon.ico";
import QRCode from "qrcode.react";

const Support = () => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      const recipient = "manianuram2312@gmail.com"; // Replace with the desired recipient email address
      const subject = encodeURIComponent("Need for Support"); // Optional: Set the email subject
      const body = encodeURIComponent("Body of the email"); // Optional: Set the email body
      const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${recipient}&su=${subject}&body=${body}`;
      window.open(gmailComposeLink);
    } else if (isAndroid) {
      window.location.href = "tel:7904544330";
    } else {
      alert("Platform not recognized.");
    }
  };
  const footerContent = (
    <div>
      <div className="flex flex-wrap justify-content-center flex-grow">
        <div className="flex flex-wrap justify-content-center flex-grow">
          <div className="w-100 m-1 d-flex justify-content-end"></div>
          <Button
            label="Done"
            icon="pi pi-check"
            onClick={() => setVisible(false)}
            style={{
              backgroundColor: "#025add",
              WebkitTextFillColor: "white",
            }}
            className="Done-button"
            autoFocus
            outlined
            rounded
          />
        </div>
      </div>
    </div>
  );
  const legendTemplate = (
    <div className="flex align-items-center gap-2 px-2">
      <Avatar
        image={agaramLogo}
        size="large"
        style={{ backgroundColor: "#2196F3", color: "#ffffff" }}
        shape="circle"
      />
      <span className="font-bold">Technical Support</span>
    </div>
  );
  return (
    <>
      <div className="container-windows">
        <TopMenuAuth />
        <div className="">
          <div className="box-form-windows">
            <div className="black-background  font">
              <Fieldset legend={legendTemplate}>
                <div className="img-support">
                  <div className="grid">
                    <div className="col-6 text-center">
                      <img
                        src={techsupport}
                        alt="Support"
                        className="responsive-img text-center"
                      />
                    </div>
                    <div className="col-6 ">
                      <p className="para-style">
                        Software Development and Maintenance Agaram, IT Services
                        provider trusted by many leading business across the
                        country offers you the most efficient Software
                        Development and maintenance services. Our applications
                        come to you at optimized costs and offer you greater
                        ROI, a faster response to market opportunities. Agaram
                        provides you the best application services, end to end
                        product development and migration services. Our Software
                        Development service has been devised keeping in mind
                        your requirements of service excellence, flexible
                        delivery models, subject-matter experts, and
                        metric-driven reporting and monitoring. We understand
                        the importance of maintaining your legacy systems, for
                        your business has heavily invested resources like money
                        and manpower in it. We are aware that any maintenance
                        activity or new development has to work with the
                        existing ecosystem and migration of the legacies to
                        contemporary technologies has to happen smoothly without
                        any loss of data. Hence Agaram works to ensure minimum
                        downtimes and maximum productivity, offers the support
                        you need to achieve your long-term objectives.
                      </p>
                      
                      <Button
                        className="w-4 support-nav-button"
                        outlined
                        label="Visit Our WebSite"
                        onClick={(e) => {
                          window.open(
                            "https://www.agaraminfotech.com",
                            "_blank"
                          );
                        }}
                        raised
                      ></Button>
                      <Button
                        className="w-4 support-nav-button"
                        outlined
                        label="Contact on mail"
                        raised
                        onClick={handleClick}
                      ></Button>
                      <Button
                        className="w-4 support-nav-button"
                        outlined
                        label="Contact on Phone"
                        onClick={(e) => {
                          setVisible(true);
                        }}
                        raised
                        
                      ></Button>
                    </div>
                  </div>
                </div>
              </Fieldset>

              <Dialog
                header="Contact Info"
                headerClassName="category-header-style"
                visible={visible}
                draggable={false}
                style={{ width: "250px", border: "1px solid black" }}
                onHide={() => setVisible(false)}
                footer={footerContent}
              >
                <div className="terms-and-conditions text-center">
                  <QRCode
                    // value={"http://cms.agaraminfotech.com/"}
                    value={"http://192.168.0.131:8024/dialor"}
                    className="qr-code-img"
                  />
                  <p>Support Contact Number</p>
                  <p>9876543210</p>
                </div>
              </Dialog>
              <div className="Footer-support-page text-center"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Support;
