import React, { useEffect, useState, useRef } from "react";
import TopMenuAuth from "../PageSetup/Tools/TopMenu_Auth";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { FloatLabel } from "primereact/floatlabel";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { confirmDialog } from "primereact/confirmdialog";
import axios from "axios";

const UserCreation = () => {
  const history = useNavigate();
  const toast = useRef(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [statusValue, setStatusValue] = useState("");
  const [GridValue, setGridValue] = useState([]);

  useEffect(() => {
    handleRefresh();
  }, []);
  const statusbardata = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "In-Active",
      value: "In-Active",
    },
  ];

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="render-clear-button-custom-datatable"
          outlined
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="keyword-search-grid"
          />
        </span>
      </div>
    );
  };
  const [formData, setFormData] = useState({
    Usercode: "",
    Username: "",
    Password: "",
    Confirmpassword: "",
    Status: "",
    Createdby: "",
    Createdon: new Date().toISOString(), // Add current date and time
    Modifiedby: "",
    Modifiedon: new Date().toISOString(), // Add current date and time
    Accountyear: 2024,
  });
  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleExport = () => {
    const dataToExport = GridValue.map((item) => ({
      usercode: item.usercode,
      username: item.username,
      status: item.status,
    }));
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "UserDetails.xlsx");
  };
  const handleRefresh = () => {
    axios
      .get("/init_usercreation") // Pass formData directly
      .then((response) => {
        setGridValue(response.data);
      })
      .catch((error) => {
        console.error("Error:", error); // Log error
        alert("An error occurred while saving data."); // Handle error case
      });
  };
  const isFormDataValid = () => {
    const requiredFields = [
      "Usercode",
      "Username",
      "Password",
      "Confirmpassword",
      "Status",
    ];

    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };
  const UserNameChecking = () => {
    const Data = GridValue.filter(
      (item) => item.usercode === formData.Usercode
    );
    if (Data.length === 0) {
      return true;
    } else {
      return false;
    }
  };
  const SaveClickHandler = () => {
    if (UserNameChecking()) {
    
    if (formData.Password === formData.Confirmpassword) {
      if (isFormDataValid()) {
        axios
          .post("/usercreation", formData) // Pass formData directly
          .then((response) => {
            if (
              response.data[0].Status !== "404" &&
              response.data[0].Status !== false
            ) {
              setFormData({
                Usercode: "",
                Username: "",
                Password: "",
                Confirmpassword: "",
                Status: "",
                Createdby: "",
                Createdon: new Date().toISOString(), // Add current date and time
                Modifiedby: "",
                Modifiedon: new Date().toISOString(), // Add current date and time
                Accountyear: 2024,
              });
              handleRefresh();
            } else {
              alert("Error: Status 404"); // Handle error case
            }
          })
          .catch((error) => {
            console.error("Error:", error); // Log error
            alert("An error occurred while saving data."); // Handle error case
          });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Info",
          detail: "Please Enter All Data!!!",
          life: 3000,
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Info",
        detail: "Password and Confirm Password not same !!",
        life: 3000,
      });
    }
  }
  else{
    toast.current.show({
      severity: "error",
      summary: "Info",
      detail: "Username" + "is Already Exist",
      life: 3000,
    });
  }

  };
  const [selectedRowData, setSelectedRowData] = useState(null);

  const onRowSelect = (event) => {
    setSelectedRowData(event.data);
  };
  const ClearHandler = () => {
    setFormData({
      Usercode: "",
      Username: "",
      Password: "",
      Confirmpassword: "",
      Status: "",
      Createdby: "",
      Createdon: new Date().toISOString(), // Add current date and time
      Modifiedby: "",
      Modifiedon: new Date().toISOString(), // Add current date and time
      Accountyear: 2024,
    });
    setStatusValue(""); // Clear the status value
  };

  const handleDelete = (rowData) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => accept(rowData),
      reject: () => reject(rowData),
    });
  };
  const accept = (rowData) => {
    const updatedGridValue = GridValue.filter((item) => item !== rowData);
    setGridValue(updatedGridValue);
  };

  const reject = (rowData) => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: rowData.usercode,
      life: 3000,
    });
  };
  const handleEdit = (rowData) => {
    setStatusValue(rowData.status);
    setFormData({
      Usercode: rowData.usercode,
      Username: rowData.username,
      Password: "",
      Confirmpassword: "",
      Status: statusValue,
      Createdby: "",
      Createdon: new Date().toISOString(), // Add current date and time
      Modifiedby: "",
      Modifiedon: new Date().toISOString(), // Add current date and time
      Accountyear: 2024,
    });
  };

  const paginatorLeft = (
    <Button
      type="button"
      icon="pi pi-refresh"
      className="paginator-button"
      onClick={() => {
        handleRefresh();
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Page Refreshed",
          life: 3000,
        });
      }}
      text
    />
  );
  const paginatorRight = (
    <Button
      type="button"
      icon="pi pi-download"
      className="paginator-button"
      onClick={handleExport}
      text
    />
  );
  return (
    <div className="container-windows">
      <Toast ref={toast} />
      <ConfirmDialog />
      <TopMenuAuth />

      <div className="">
        <div className="box-form-windows">
          <div className="black-background  font">
            <h1
              style={{ WebkitTextFillColor: "black", textAlign: "center" }}
              tabIndex={0}
            >
              User Creation
            </h1>
            <br />

            <div className="grid">
              <div className="field col-6">
                <div className="card justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Usercode"
                      name="Usercode"
                      type="text"
                      placeholder="Usercode"
                      className="form-textbox-windows"
                      onChange={handleChange}
                      value={formData.Usercode}
                    />
                    <label htmlFor="status" className="label-field">
                      Usercode
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-6">
                <div className="card  justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="Username"
                      type="text"
                      name="Username"
                      placeholder="username"
                      className="form-textbox-windows"
                      value={formData.Username}
                      onChange={handleChange}
                    />
                    <label htmlFor="status" className="label-field">
                      Username
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-3">
                <div className="card  justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="password"
                      type="password"
                      name="Password"
                      placeholder="Password"
                      className="form-textbox-windows"
                      onChange={handleChange}
                    />
                    <label htmlFor="status" className="label-field">
                      Password
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-3">
                <div className="card  justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="confirmpassword"
                      type="password"
                      placeholder="Confirm Password"
                      name="Confirmpassword"
                      className="form-textbox-windows"
                      onChange={handleChange}
                    />
                    <label htmlFor="status" className="label-field">
                      Confirm Password
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div className="field col-3">
                <div className="card  justify-content-center">
                  <FloatLabel>
                    <Dropdown
                      value={statusValue}
                      options={statusbardata}
                      optionLabel="label"
                      name="Status"
                      placeholder="Select a Status"
                      className="form-select-windows"
                      onChange={(e) => {
                        setStatusValue(e.target.value);
                        handleChange(e);
                      }}
                    />
                    <label htmlFor="status" className="label-field">
                      Select a Status
                    </label>
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap justify-content-center flex-grow">
              <div className="flex flex-wrap justify-content-center flex-grow">
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-check"
                    rounded
                    outlined
                    aria-label="Next"
                    label="Save"
                    className="Submit-button"
                    onClick={SaveClickHandler}
                  />
                </div>
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-trash"
                    rounded
                    outlined
                    aria-label="Next"
                    label="Clear"
                    className="Clear-button"
                    onClick={ClearHandler}
                  />
                </div>
                <div className="w-100 m-1 d-flex justify-content-end">
                  <Button
                    icon="pi pi-arrow-left"
                    rounded
                    outlined
                    aria-label="Next"
                    label="Back"
                    className="Back-button"
                    onClick={() => history("/reportofcomplients")}
                  />
                </div>
              </div>
            </div>
            <br />
            <div className="card">
              <DataTable
                value={GridValue}
                paginator
                rows={5}
                filters={filters}
                showGridlines
                rowsPerPageOptions={[5, 10, 25, 50]}
                header={renderHeader()}
                globalFilterFields={["usercode", "username", "status"]}
                className="custom-datatable"
                selectionMode="single"
                selection={selectedRowData}
                onSelectionChange={onRowSelect}
                onRowClick={onRowSelect}
                paginatorRight={paginatorRight}
                paginatorLeft={paginatorLeft}
              >
                {/* <Column
                  style={{ width: "2rem" }}
                  body={(rowData) => (
                    <div
                      className="flex justify-between "
                      style={{ margin: "0.5px" }}
                    >
                      <Button
                        icon="pi pi-pencil"
                        severity="warning"
                        className="Edit-button"
                        outlined
                        text
                        onClick={() => {
                          handleEdit(rowData);
                        }}
                      />

                      <Button
                        icon="pi pi-times"
                        severity="danger"
                        className="Trash-button"
                        outlined
                        text
                        onClick={() => handleDelete(rowData)}
                      />
                    </div>
                  )}
                /> */}
                <Column
                  field="usercode"
                  header="usercode"
                  filterPlaceholder="Search by Sno"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  field="username"
                  header="username"
                  filterPlaceholder="Search by Category"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  field="status"
                  header="status"
                  filterPlaceholder="Search by Complaint"
                  style={{ minWidth: "12rem" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserCreation;
