import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TopMenuAuth from "../PageSetup/Tools/TopMenu_Auth";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import "../Asset/Css/Common.css";
import axios from "axios";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import ApprovalDetails from "./ApprovalDetails";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

const ApprovalReport = () => {
  const [visible, setVisible] = useState(false);
  const [donwloadVisible, setdonwloadVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [fromDate, setfromDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [toDate, settoDate] = useState(new Date());
  const [lang, Langset] = useTranslation("global");
  const handleLangChange = (lang) => {
    Langset.changeLanguage(lang);
    sessionStorage.setItem("lang", lang);
  };
  const onRowSelect = (event) => {
    setSelectedRowData(event.data);
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const toast = useRef(null);

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          outlined
          className="render-clear-button-custom-datatable"
          onClick={(e) => {
            onGlobalFilterChange(e);
          }}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
            className="keyword-search-grid"
          />
        </span>
      </div>
    );
  };
  const [DataReports, setDataReports] = useState({});
  useEffect(() => {
    const fetchData = () => {
      axios
        .post("/reportApprovalGenerate ", {
          fromDate: fromDate,
          toDate: toDate,
        })
        .then((response) => {
          setDataReports(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("An error occurred while saving data.");
        });
    };
    fetchData();
    const interval = setInterval(fetchData, 1000000);
    return () => clearInterval(interval);
  }, []);

  const handleExport = (e) => {};
  const handleRefresh = () => {
    axios
      .post("/reportApprovalGenerate ", {
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((response) => {
        setDataReports(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while saving data.");
      });
  };
  const paginatorLeft = (
    <Button
      type="button"
      icon="pi pi-refresh"
      className="paginator-button"
      onClick={() => {
        handleRefresh();
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "Page Refreshed",
          life: 3000,
        });
      }}
      text
    />
  );
  const paginatorRight = (
    <Button
      type="button"
      icon="pi pi-download"
      className="paginator-button hidden"
      onClick={handleExport}
      text
    />
  );
  const handleRow = (rowData) => {};
  const handleGenerate = () => {
    axios
      .post("/reportApprovalGenerate ", {
        fromDate: fromDate,
        toDate: toDate,
      })
      .then((response) => {
        setDataReports(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while saving data.");
      });
  };
  return (
    <div className="container-windows">
      <Toast ref={toast} />
      <TopMenuAuth />
      <div className="">
        <div className="box-form-windows">
          <div className="black-background  font">
            <h2
              style={{
                WebkitTextFillColor: "black",
                textAlign: "center",
                fontSize: "x-large",
              }}
              tabIndex={0}
              className="report-header"
            >
              Approval Report
            </h2>
            <br />
            <br />
            <div class="grid">
              <div class="col-2 col-offset-1">
                <div class="text-center p-3 border-round-sm  font-bold">
                  <FloatLabel>
                    <Calendar
                      value={fromDate}
                      onChange={(e) => {
                        setfromDate(e.value);
                      }}
                      dateFormat="dd/mm/yy"
                      maxDate={toDate}
                      touchUI
                    />
                    <label htmlFor="status" className="label-field">
                      From Date
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div class="col-2 col-offset-1">
                <div class="text-center p-3 border-round-sm  font-bold">
                  <FloatLabel>
                    <Calendar
                      value={toDate}
                      onChange={(e) => settoDate(e.value)}
                      dateFormat="dd/mm/yy"
                      touchUI
                      minDate={fromDate}
                    />
                    <label htmlFor="status" className="label-field">
                      To Date
                    </label>
                  </FloatLabel>
                </div>
              </div>
              <div class="col-2 col-offset-0">
                <div class="text-center p-3 border-round-sm font-bold">
                  <FloatLabel>
                    <Button
                      label="   Search"
                      className="Evidance-button pi pi-search"
                      onClick={handleGenerate}
                    />
                  </FloatLabel>
                </div>
              </div>
              <div class="col-2 col-offset-0">
                <div class="text-center p-3 border-round-sm font-bold">
                  <FloatLabel>
                    <Button
                      label=" Excel"
                      className="Excel-button pi pi-download"
                      onClick={() => {
                        setdonwloadVisible(true);
                      }}
                      icon=""
                    />
                  </FloatLabel>
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={DataReports.complaint}
                paginator
                rows={5}
                filters={filters}
                showGridlines
                rowsPerPageOptions={[5, 10, 25, 50]}
                header={renderHeader()}
                globalFilterFields={[
                  "FORM_NUMBER",
                  "ApprovalStatus",
                  "CREATEDON",
                ]}
                paginatorLeft={paginatorLeft}
                paginatorRight={paginatorRight}
                className="custom-datatable"
                selectionMode="single"
                selection={selectedRowData}
                onSelectionChange={onRowSelect}
                onRowClick={onRowSelect}
              >
                <Column
                  style={{ width: "2rem" }}
                  body={(rowData) => (
                    <div
                      className="flex justify-between"
                      style={{ margin: "0.5px" }}
                    >
                      <Button
                        className="popup-button"
                        icon="pi pi-external-link"
                        tooltip="View"
                        onClick={() => {
                          handleRow(rowData);
                          setSelectedRowData(rowData);
                          setVisible(true);
                        }}
                      />
                      {/* <Button
                        className="popup-button"
                        icon="pi pi-undo"
                        tooltip="Restore"
                        onClick={() => {
                          handleRestore(rowData);
                          setSelectedRowData(rowData);
                        }}
                      /> */}
                    </div>
                  )}
                />
                <Column
                  field="FORM_NUMBER"
                  header="Complaint Code"
                  filterPlaceholder="Search by FORM_NUMBER"
                  style={{ minWidth: "12rem" }}
                />
                <Column
                  field="ApprovalStatus"
                  header="Approval Status"
                  filterPlaceholder="Search by ApprovalStatus"
                  style={{ minWidth: "12rem" }}
                />

                <Column
                  field="FCREATEDON"
                  header="Created Date and Time"
                  filterPlaceholder="Search by createdon"
                  style={{ minWidth: "12rem" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={"Details About Complaint : "}
        visible={visible}
    
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <ApprovalDetails data={selectedRowData} />
      </Dialog>

      <Dialog
        header={"Excel Download Options"}
        headerClassName="category-header-style"
        visible={donwloadVisible}
        draggable={false}
        style={{ width: "30rem" }}
        onHide={() => setdonwloadVisible(false)}
      >
        <div className="field col-offset-1 text-center">
          <br />
          <Button
            name="unethical"
            label={" Unethical"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "unethical",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "Unethical_" +
                      format(new Date(), "yyyy-MM-dd HH:mm") +
                      ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No Data Found",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            outlined
          ></Button>
        </div>
        <div className="field col-offset-1 text-center">
          <Button
            name="bribery"
            label={" Bribery"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "bribery",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "Bribery_" +
                      format(new Date(), "yyyy-MM-dd HH:mm") +
                      ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No Data Found",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            value={"bribery"}
            outlined
          />
        </div>
        <div className="field col-offset-1 text-center">
          <Button
            name="abuse"
            label={" Abuse"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "Abuse",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "Abuse_" + format(new Date(), "yyyy-MM-dd HH:mm") + ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No Data Found",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            outlined
          />
        </div>
        <div className="field col-offset-1 text-center">
          <Button
            name="harassment"
            label={" Harassment"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "harassment",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "Harassment_" +
                      format(new Date(), "yyyy-MM-dd HH:mm") +
                      ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No Data Found",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            outlined
          />
        </div>
        <div className="field col-offset-1 text-center">
          <Button
            name="others"
            label={" Others"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "Others",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "Others_" + format(new Date(), "yyyy-MM-dd HH:mm") + ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No Data Found",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            outlined
          />
        </div>
        <div className="field col-offset-1 text-center">
          <Button
            name="all"
            label={" All Download"}
            onClick={(e) => {
              axios
                .post("/xl_download_approval ", {
                  fromDate: fromDate,
                  toDate: toDate,
                  TYPEOFCOMPLAINT: "all",
                })
                .then((response) => {

                  const dataToExport = response.data.map((item) => ({
                    Form_Number: item.FORM_NUMBER,
                    Username: item.USERNAME,
                    Department: item.DEPARTMENTNAME,
                    YearsOfExperience: item.YEARSOFEXP,
                    Improper_Activity: item.IMPROPERACTIVITY,
                    Who_Committed: item.WHOCOMMITED,
                    When_You_Realized: item.WHENYOUREALIZED,
                    Evidence: item.EVIDANCE,
                    Others: item.OTHERS,
                    Approval_Status: item.ApprovalStatus,
                    Type: item.TYPE,
                    Choose_Category: item.CHOOSECATEGORY,
                    H_EmployeeName: item.HEMPLOYEENAME,
                    H_Evidence: item.HEVIDENCE,
                    H_Department: item.HDEPARTMENT,
                    H_DetailDescription: item.HDETAILDESCRIPTION,
                    S_EmployeeName: item.SEMPLOYEENAME,
                    S_eeNo: item.SEENO,
                    S_Department: item.SDEPARTMENT,
                    S_Contact: item.SCONTACT,
                    W_EmployeeName: item.WEMPLOYEENAME,
                    W_EENo: item.WEENO,
                    W_Department: item.WDEPARTMENT,
                    W_Contact: item.WCONTACT,
                  }));
                  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
                  const workbook = XLSX.utils.book_new();
                  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
                  XLSX.writeFile(
                    workbook,
                    "All_" + format(new Date(), "yyyy-MM-dd HH:mm") + ".xlsx"
                  );
                })
                .catch((error) => {
                  console.error("Error:", error);
                  toast.current.show({
                    severity: "error",
                    summary: "Info",
                    detail: "No ",
                    life: 3000,
                  });
                });
            }}
            className="Excel-button pi pi-download"
            outlined
          />
        </div>
        {/* <div className="field col-offset-1 text-center">
          <Button
            name="all"
            label={" Close"}
            onClick={() => {
              window.location.reload();
            }}
            className="Trash-button pi pi-times"
            outlined
          />
        </div> */}
      </Dialog>
    </div>
  );
};
export default ApprovalReport;
