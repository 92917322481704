import React, {  useRef } from "react";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";

const ImageComponent = () => {
  const toast = useRef(null);
  const navigate = useNavigate();

  window.onbeforeprint = () => {
    console.log("Before print event triggered");
    window.print();
  };

  window.onafterprint = () => {
    console.log("After print event triggered");
    navigate("/qr_code");
  };
  return (
    <div className="Print">
      <Toast ref={toast} />
      <div className="QR-box">
        <div className="QR-box-inner text-center">
          <h1 className="qr-para" style={{ fontSize: "x-large" }}>
            For Complaint{" "}
          </h1>
          <div className="grid ">
            <div className="col-6 col-offset-3">
              <QRCode value={"http://cms.agaraminfotech.com/"} className="qr-code-img" />
            </div>
          </div>
          <div className="col-12 text-center">
            <p className="qr-para">Scan to Get Mobile Version</p>
            <p className="qr-para">Powered by @Agaram Info tech</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageComponent;
