import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";

const QR = () => {
  const qrRef = useRef(null);
  const navigate = useNavigate();
  const [visible, setVisible] = useState("");

  const downloadQRCode = () => {
    const canvas = qrRef.current.querySelector("canvas");
    const qrCodeURL = canvas.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.href = qrCodeURL;
    downloadLink.download = "qr_code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const reportRef = useRef(null);

  const downloadAsPNG = () => {
    if (reportRef.current) {
      html2canvas(reportRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.download = "report.png";
        link.href = canvas.toDataURL("image/png");
        link.click();
      });
    }
  };
  const footerContent = (
    <div>
      <div className="flex flex-wrap justify-content-center flex-grow">
        <div className="flex flex-wrap justify-content-center flex-grow">
          <div className="w-100 m-1 d-flex justify-content-end"></div>
          <Button
            label="Ok"
            icon="pi pi-check"
            onClick={() => {
              setVisible(false);
              navigate("/test");
            }}
            style={{
              backgroundColor: "#025add",
              WebkitTextFillColor: "white",
            }}
            className="Done-button"
            autoFocus
            outlined
            rounded
          />
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className="QR-container" ref={qrRef}>
        <div className="flex flex-wrap align-items-center justify-content-center">
          <div className="flipleft animation-duration-1000 font-bold  border-round">
            <div className="QR-box">
              <div className="QR-box-inner">
                <div className="grid ">
                  <div className="col-6 col-offset-3">
                    <QRCode
                      value={"http://cms.agaraminfotech.com/"}
                      className="qr-code-img"
                    />
                  </div>
                </div>
                <div className="col-12 text-center">
                  <p className="qr-para">Windows Not Support This Form</p>
                  <p className="qr-para">Scan to Get Mobile Version</p>
                  <p>
                    <a href="/login">Admin Access</a>
                  </p>
                  <Button
                    className="  w-50%"
                    label={" Qr Code"}
                    icon="pi pi-print"
                    onClick={() => {
                      setVisible(true);
                      //  navigate("/test");
                    }}
                    outlined
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="ⓘ Information"
        headerClassName="category-header-style"
        visible={visible}
        draggable={false}
        style={{ width: "250px", border: "1px solid black" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        <div className="terms-and-conditions">
          <p>
            To print the page, press Ctrl + P. After printing, the page will
            automatically return to this page.
          </p>
        </div>
      </Dialog>
    </>
  );
};

export default QR;
