import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../../Asset/Css/Common.css";
import { Dialog } from "primereact/dialog";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { PrimeIcons } from "primereact/api";
import { Button } from "primereact/button";
import imageLogo from "../../Asset/Images/HTLI-LOGO.png";
import { Tooltip } from "primereact/tooltip";

const TopMenu_Auth = () => {
  const history = useNavigate();
  const toast = useRef(null);
  const menuRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [reportVisible, setreportVisible] = useState(false);
  useEffect(() => {
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      if (sessionStorage.length === 0) {
        history("/login");
      }
      if (sessionStorage.getItem("username") !== "logout") {
        // history("/reportofcomplients");
      } else {
        history("/login");
      }
    } else if (isAndroid) {
      // Handle Android platform
    } else {
      alert("Platform not recognized.");
    }
  }, [history]);
  const items = [
    {
      label: (
        <img
          src={imageLogo}
          alt="Company Logo"
          sizes="180px"
          className="company-logo"
        />
      ),
    },
  ];
  const [search, setSearch] = useState("");

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.label === "Log Out") {
      sessionStorage.setItem("username", "logout");
      history("/");
    } else if (menuItem.label === "User Creation") {
      history("/usercreation");
    } else if (menuItem.label === "Dashboard") {
      history("/dashboard");
    } else if (menuItem.label === "User Profile") {
      toast.current.show({
        severity: "info",
        summary: "User Profile",
        detail: "Anonymous",
        life: 3000,
        position: "bottom-left",
      });
    }
  };

  const SplitItem = [
    {
      label: "User Creation",
      icon: PrimeIcons.USER_PLUS,
      command: () => handleMenuItemClick({ label: "User Creation" }), // Command for logout item
    },
  ];
  const handleClick = () => {
    alert("If you want Support Please mail it!!");
    const isWindows = window.navigator.platform.toUpperCase().includes("WIN");
    const isAndroid = window.navigator.userAgent
      .toLowerCase()
      .includes("android");

    if (isWindows) {
      const recipient = "support@agaraminfotech.com"; // Replace with the desired recipient email address
      const subject = encodeURIComponent("Need for Support"); // Optional: Set the email subject
      const body = encodeURIComponent("Body of the email"); // Optional: Set the email body
      const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${recipient}&su=${subject}&body=${body}`;
      window.open(gmailComposeLink);
    } else if (isAndroid) {
      window.location.href = "tel:7904544330";
    } else {
      alert("Platform not recognized.");
    }
  };
  const SearchRef = useRef(null);
  const [showInput, setShowInput] = useState(true);
  const [searchData, setSearchData] = useState("");
  const endItems = [
    // <span className="p-input-icon-left" hidden={showInput}>
    //   <i
    //     className="pi pi-search"
    //     style={{ color: "#014fc3" }}
    //     hidden={showInput}
    //   />
    //   <InputText
    //     placeholder="Screen Search"
    //     ref={SearchRef}
    //     hidden={showInput}
    //     icon="pi pi-search"
    //   />
    // </span>,
    // <Button
    //   key="button-search"
    //   box
    //   icon="pi pi-search"
    //   fontFamily="hack, monospace"
    //   className="p-button-icon-form-windows"
    //   outlined
    //   rounded
    //   onClick={(e) => {
    //     setSearchData(e.target.value);
    //     if (showInput) {
    //       setShowInput(false);
    //     } else {
    //       setShowInput(true);
    //     }
    //     if (SearchRef.current) {
    //       SearchRef.current.focus();
    //     }
    //   }}
    //   tooltip="Search"
    //   tooltipOptions={{ position: "bottom" }}
    // />,
    sessionStorage.getItem("username") === "superadmin" ? (
      <Button
        icon="pi pi-gauge"
        className="p-button-icon-form-windows"
        model={SplitItem}
        onClick={(e) => history("/dashboard")}
        rounded
        outlined
        tooltip="DashBoard"
        tooltipOptions={{ position: "bottom" }}
      />
    ) : (
      <span></span>
    ),
    <Button
      icon="pi pi-home"
      rounded
      outlined
      className="p-button-icon-form-windows"
      //onClick={handleClick}
      onClick={() => history("/reportofcomplients")}
      tooltip="Home"
      tooltipOptions={{ position: "bottom" }}
    />,
    // <Button
    //   icon="pi pi-question-circle"
    //   rounded
    //   outlined
    //   className="p-button-icon-form-windows"
    //   //onClick={handleClick}
    //   onClick={() => history("/Support")}
    //   tooltip="Technical Support"
    //   tooltipOptions={{ position: "bottom" }}
    // />,
    <Button
      icon="pi pi-objects-column"
      rounded
      outlined
      className="p-button-icon-form-windows"
      tooltip="Report Screen"
      onClick={() => {
        setreportVisible(true);
      }}
      tooltipOptions={{ position: "bottom" }}
    />,
    <span className="p-input-icon-left" key="searchInput">
      <Dialog
        showHeader="false"
        visible={visible}
        style={{ width: "250px", backgroundColor: "red" }}
        onHide={() => setVisible(false)}
        position="top-right"
      >
        <div className="col-12">
          <InputText
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>

        <div className="col-12">
          <Button
            className="end-item-size"
            label="search"
            onClick={(e) => {
              if (search !== "") {
                history("/" + search);
              } else {
                toast.current.show({
                  severity: "warn",
                  summary: "Error",
                  detail: "Please Enter Screen Name",
                  life: 3000,
                });
              }
            }}
            tooltip="Search"
          />
        </div>
      </Dialog>
    </span>,

    <Button
      icon="pi pi-user"
      className="p-button-icon-form-windows"
      model={SplitItem}
      onClick={(e) => history("/usercreation")}
      rounded
      outlined
      tooltip="User"
      tooltipOptions={{ position: "bottom" }}
    />,

    <Button
      icon="pi pi-sign-out"
      className="p-button-icon-form-windows custom-target-icon"
      model={SplitItem}
      onClick={(e) => {
        sessionStorage.setItem("username", "logout");
        history("/login");
      }}
      rounded
      outlined
      tooltip="LogOut"
      tooltipOptions={{ position: "bottom" }}
    />,
  ];

  return (
    <div>
      <Menubar
        menuRef={menuRef}
        start={
          <img
            src={items[0].label.props.src}
            alt={items[0].label.props.alt}
            sizes={items[0].label.props.sizes}
            className={items[0].label.props.className}
          />
        }
        end={endItems}
        className="custom-menubar-windows"
        outlined
      />
      <Tooltip target=".custom-target-icon" />
      <Toast ref={toast} position="bottom-right" />
      <Dialog
        header={"Report Screen"}
        visible={reportVisible}
        onHide={() => setreportVisible(false)}
        style={{ width: "30rem", height: "auto" }}
        position="fixed"
      >
        <div className="grid">
          <div className="field col-4">
            <div className="field col-offset-1 text-center">
              <Button
                label="Approval Report"
                className="Evidance-button"
                model={SplitItem}
                onClick={(e) => history("/ApprovalReport")}
                rounded
                outlined
                tooltip="Approval Report"
                tooltipOptions={{ position: "bottom" }}
              />
            </div>
            <div className="field col-offset-1 text-center">
              <Button
                label="Rejection Report"
                className="Evidance-button"
                model={SplitItem}
                onClick={(e) => history("/RejectionReport")}
                rounded
                outlined
                tooltip="Rejection Report"
                tooltipOptions={{ position: "bottom" }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TopMenu_Auth;
